//デバッグ用
(function ($) {
	let timeStamp		=	"";
	let timerMousedown	=	0;

	$(window).on("mousedown", function (e) {
		timerMousedown = new Date().getTime();
	});

	$(window).on("mouseup", function (e) {
		if (timerMousedown + 300 <= new Date().getTime()) {
			if (e.button == 2) {
				e.preventDefault();

				setTimeStamp();
			}
		}
	});

	function checkStamp() {
		$.ajax({
			url:'/resource/complete.log',
			type:'GET',
		}).done(function (result) {
			if(timeStamp != result) {console.log(1)
				setTimeStamp();
			}

			timeStamp	=	result;

			setTimeout(function () {
				checkStamp();
			}, 1000);
		});
	}

	checkStamp();

	function setTimeStamp() {
		var queryString = '?' + new Date().getTime();

		$('link[rel="stylesheet"]').each(function () {
			let href	=	this.href;

			if (href.match(/\?\d+$/)) {
				this.href = href.replace(/\?.*$/, queryString);
			}
			else {
				this.href	=	href + queryString;
			}
		});

		$(window).one("contextmenu", function (e) {
			e.preventDefault();
		});
	}
})(jQuery);